import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ContactFormOverlay from "../components/contactFormOverlay"

import AppleIcon from "../images/apple-icon.svg"
import GooglePlayIcon from "../images/google-play-icon.png"
import HeaderMockup from "../images/home-header-mockup.png"
import ReliableIcon from "../images/reliable-icon.svg"
import SafeIcon from "../images/safe-icon.svg"
import AffordableIcon from "../images/affordable-icon.svg"

import WhereToIcon from "../images/where-to.png"
import SelectYourRouteIcon from "../images/select-your-route.png"
import BookYourRideIcon from "../images/book-your-ride.png"

import MoveMinivan from "../images/advertise-van.png"
import PepsiLogo from "../images/pepsi-logo.png"
import EngroFoods from "../images/engro-foods.png"
import Dawaai from "../images/dawaai.png"
import Carfirst from "../images/carfirst.png"
import Hatchful from "../images/hatchful.jpg"
import Nasa from "../images/nasa.png"

import LetsMoveMockup from "../images/lets-move-mockup.png"
import RideWantedMockup from "../images/always-the-ride.png"

import BGShapesHeader from "../images/bg-shapes-header.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home-page">
      <div className="hero">
        <div className="bg-element">
          <img src={BGShapesHeader} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="content">
              <h1 className="title">
                <strong>Innovative Online</strong> <br /> Public Transportation{" "}
                <br />
                In Pakistan
              </h1>
              <p>
                MOVE is the exceptional alternative to facilitate public
                transportation in Pakistan. Nowadays with fully aware consumers,
                it is our social responsibility to design such a policy that
                shows our commitment towards our valuable customers.
              </p>
              <p>
                Through our app, you can book flexible rate affordable rides on
                our existing routes without any hassle.
              </p>
              <div className="download-cta">
                <h3>Download Now</h3>
                <div className="btn-group">
                  <a href="#" className="btn btn-apple">
                    <img src={AppleIcon} />
                    <div>
                      Get it on
                      <span>App Store</span>
                    </div>
                  </a>
                  <a href="#" className="btn btn-play-store">
                    <img src={GooglePlayIcon} />
                    <div>
                      Get it on
                      <span>Google Play</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="section-image">
              <img src={HeaderMockup} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="why-move">
        <div className="container">
          <h2 className="section-title">Why Move?</h2>
          <div className="row">
            <div className="reason">
              <img src={ReliableIcon} />
              <h3>Reliable</h3>
              <p>
                MOVE live tracking feature helps you track your ride at all
                times, without facing any difficulties.
              </p>
            </div>
            <div className="reason">
              <img src={SafeIcon} />
              <h3>Safe</h3>
              <p>
                MOVE is committed to creating a safe environment for commuters
                and drivers and also believes in prioritizing the safety of its
                customers first.
              </p>
            </div>
            <div className="reason">
              <img src={AffordableIcon} />
              <h3>Affordable</h3>
              <p>
                MOVE is committed to maintaining more cost-effective prices than
                any other service providers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="lets-move" className="lets-move">
        <div className="bg"></div>
        <div className="container">
          <div className="row">
            <div className="content">
              <h2 className="section-title">Let's Move</h2>
              <p>
                MOVE is always that premium ride everyone wants. Forget about
                the rush hours, traffic, busy streets, terrible driving habits
                and unavailable parking spots! All you just need is to request a
                ride, hop in, relax and  go to the desired destinations with
                MOVE.
              </p>
              <div className="download-cta">
                <h3>Download Now</h3>
                <div className="btn-group">
                  <a href="#" className="btn btn-apple">
                    <img src={AppleIcon} />
                    <div>
                      Get it on
                      <span>App Store</span>
                    </div>
                  </a>
                  <a href="#" className="btn btn-play-store">
                    <img src={GooglePlayIcon} />
                    <div>
                      Get it on
                      <span>Google Play</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="section-image">
              <img src={LetsMoveMockup} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="how-it-works" className="how-it-works">
        <div className="container">
          <header>
            <h2 className="section-title">How Does it Work?</h2>
            <p>
              MOVE ambitiously desires to develop a technology that connects
              commuters and drivers in such a smooth manner that going anywhere
              reliably and easily won’t be much easier than this before. With
              the tap of a button choose the best option that suits your needs.
            </p>
            <p>Booking your ride only requires three simple steps:</p>
          </header>
          <div className="row">
            <div className="feature">
              <h5>01</h5>
              <img src={WhereToIcon} />
              <h3>Enter your starting point and where you want to go</h3>
            </div>
            <div className="feature">
              <h5>02</h5>
              <img src={SelectYourRouteIcon} />
              <h3>Book your seat through our application</h3>
            </div>
            <div className="feature">
              <h5>03</h5>
              <img src={BookYourRideIcon} />
              <h3>On our designated stop, simply onboard with us</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="advertise-with-us" className="advertise-with-us">
        <div className="container">
          <div className="row">
            <div className="col content-col">
              <h2 className="section-title">Advertise with us</h2>
              <p>
                MOVE is seeking further exclusive brand partners to be promoted
                heavily and to be visibly doubled within no time as MOVE
                believes in empowering your brands and serves the local
                population in most comfortable yet reliable manner.
              </p>
              <div className="email">
                {/* <ContactFormOverlay />
                <h5>OR</h5> */}
                <div>
                  <h4>Email us at</h4>
                  <a href="mailto:advertise@letsmovecity.com">
                    <h3>advertise@letsmovecity.com</h3>
                  </a>
                </div>
              </div>
            </div>
            <div className="col img-col">
              <img src={MoveMinivan} />
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="about-move">
        <div className="container">
          <h2 className="section-title">About Move</h2>
          <div className="row">
            <div className="col">
              <p className="large">
                MOVE is a role model and trend setting public transportation
                company in Pakistan which continuously thrives to facilitate the
                local population through local transporters. MOVE was just a
                reflection of our thoughts that turned into reality. MOVE is
                currently operating in Karachi, Pakistan with the vision to
                expand its operations all over Pakistan with substantial
                increase in the number of buses.
              </p>
              <p>
                MOVE is a smart technology based solution created to solve the
                transportation equation, leading to help more commuters to go
                anywhere they want for less, with absolute ease and comfort.
                Thriving into a fast paced environment isn’t easy for MOVE as
                well but for us protecting our customers from consequent pains
                of moving around in rush hours, traffic, and unavailability of
                parking spots strikes our minds. In addition to the pain of high
                cost of on-demand services, MOVE desires to enable its customers
                at first and goes beyond gaining any market share.
              </p>
              <div className="download-cta">
                <h3>Download Now</h3>
                <div className="btn-group">
                  <a href="#" className="btn btn-apple">
                    <img src={AppleIcon} />
                    <div>
                      Get it on
                      <span>App Store</span>
                    </div>
                  </a>
                  <a href="#" className="btn btn-play-store">
                    <img src={GooglePlayIcon} />
                    <div>
                      Get it on
                      <span>Google Play</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col">
              <p>
                Move is a revolutionary idea that was born from passion,
                loyalty, and persistence to confront all the challenges. Move is
                not just a mean to facilitate commuting, but a hunger to strive
                for solutions, encourage the contribution of youth in innovation
                and inspire CHANGE.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="rides" className="ride-wanted">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="section-title">
                Always The Ride
                <div>You Want</div>
              </h2>
              <p>Request a ride, hop in, and relax.</p>{" "}
              {/*<div className="locations">
                <div className="location">North Nazimambad</div>
                <div className="location">Defence</div>
                <div className="location">Malir</div>
                <div className="location">Gulshan e Iqbal</div>
              </div>
              <h3>
                <span>+800</span> Lines in Karachi, Pakistan.
              </h3> */}
              <p>Your only way to a better daily commute.</p>
            </div>
            <div className="col img-col">
              <img src={RideWantedMockup} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
