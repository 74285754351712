import React from "react"
import Modal from "react-modal"
import TextField from "@material-ui/core/TextField"
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { makeStyles, withStyles } from "@material-ui/core";
import qs from 'qs'; 
Modal.setAppElement(`#___gatsby`)

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: '15px',
    zIndex: 10,
    height: '900px', // <-- This sets the height
    overflow: 'scroll' // <-- This tells the modal to scrol
  },
}

const styles = {
  formControl: {
    marginBottom: '40px',
    "& $label": {
      color: '#1d3046'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    boxShadow: 'none',
    borderColor: '#d9e2ee',
  },
  floatingLabelFocusStyle: {
    color: '#1d3046',
  },
  focused: {
    "& $notchedOutline": {
      borderColor: "#c90107!important",
      borderWidth: "1px!important"
    },
    
  }
}

class ContactFormOverlay extends React.Component {
  constructor() {
    super();
    this.state = { 
      modalIsOpen: false,
      name: "",
      phoneNumber: "",
      description: "",
      city: '',
      isSubmitting: false,
      status: null,
      message: '',
    }
  }

  setIsOpen = () => {
    this.setState({modalIsOpen: true});
  }
  
  openModal = () => {
    this.setIsOpen(true);
    this.setState({
      isSubmitting: false,
      status: null,
      message: '',
    });
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  closeModal = () => { 
    this.setState({modalIsOpen: false});
  }

  handleServerResponse = (ok, msg, form) => {
    this.setState({
      isSubmitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  handleOnSubmit = e => {
    const _this = this;
    const form = e.target;
    _this.setState({ isSubmitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/e1fd4c17-01d3-4d10-87c9-f9b7ca2ddc4e",
      data: new FormData(form)
    })
      .then(r => {
        console.log('Success')
        _this.handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        _this.handleServerResponse(false, r.response.data.error, form);
      });
      e.preventDefault();
    };

  render () {
    const { classes } = this.props;
  return (
    <div>
      <button className="btn btn-primary" onClick={this.openModal}>
        Let's Connect
      </button>
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contact-form">
          <header className="contact-form-header">
            <h2>Contact Us</h2>
            <p>Lorem ipsum dolor sit amet, cum eu dicta mucius summo graece eu qui ut vim posse vidisse.</p>
          </header>
          <button className="close-btn" onClick={this.closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.828" height="16.828">
              <g fill="none" stroke="#1d3046" strokeLinecap="round" strokeWidth="2px" transform="translate(-16037.586 -610.586)">
                <path d="M16039 612l14 14" />
                <path d="M0 0l14 14" transform="rotate(-90 8332.5 -7706.5)"/>
              </g>
            </svg>
          </button>
          <form onSubmit={this.handleOnSubmit}>
            <FormControl className={classes.formControl} fullWidth variant="filled">
            <TextField
              id="name"
              name="name"
              value={this.state.name}
              InputLabelProps={{
                shrink: true,
                classes: classes.floatingLabelFocusStyle
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  focused: classes.focused
                }
              }}
              label="Name"
              variant="outlined"
              required
              onChange={this.handleInputChange}

            />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth variant="filled">
              <TextField
                id="phone-number"
                name="phoneNumber"
                value={this.state.phoneNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    focused: classes.focused
                  }
                }}
                label="Phone Number"
                variant="outlined"
                required
                onChange={this.handleInputChange}
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth variant="filled">
              <TextField
                id="description"
                name="description"
                value={this.state.description}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    focused: classes.focused
                  }
                }}
                label="Description"
                variant="outlined"
                multiline
                rows={8}
                required
                onChange={this.handleInputChange}
                helperText="Please enter the details of your request. A member of our support staff will respond as soon as possible."
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth variant="filled">
              <TextField
                id="city"
                name="city"
                value="karachi"
                select
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    focused: classes.focused
                  }
                }}
                label="City"
                variant="outlined"
                value={this.state.city}
                onChange={this.handleInputChange}
              >
                <MenuItem key="karachi" value="karachi">
                  Karachi
                </MenuItem>
                <MenuItem key="lahore" value="lahore">
                  Lahore
                </MenuItem>
                <MenuItem key="islamabad" value="islamabad">
                  Islamabad
                </MenuItem>
              </TextField>
            </FormControl>
            {this.state.status && (
                <p className={!this.state.status.ok ? "errorMsg" : ""}>
                {this.state.status.msg}
                </p>
            )}
            <button type="submit" className="btn btn-primary" >
              {this.state.isSubmitting ? 'Loading ...' : 'Submit'}
            </button>
          </form>
        </div>
      </Modal>
    </div>
  )
}
}

export default withStyles(styles)(ContactFormOverlay);
